import { CloudinaryImageType } from './blockTypes';
import { LinkType } from './LinkType';

export enum ValidationType {
  postalCode = 'postalCode',
  noValidation = 'noValidation',
}

export enum ValidationMessage {
  postalCode = 'Dette er ikke et gyldig postnummer.',
}

export interface InputWithImageLinkType {
  title: string;
  image: CloudinaryImageType;
  link: LinkType;
  key: string;
  type?: string;
  label?: string;
  validation: ValidationType;
}
