import React, { useEffect, useState, useRef, useCallback } from 'react';
import { spacing, breakpoints } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { Input, Label, Message } from '@naf/input';
import { Button } from '@naf/button';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useDocumentUrlWithNode } from '../../../../hooks/useDocumentUrl';
import { ValidationType, ValidationMessage } from '../../../../../../types/inputWithImageLinkType';
import { LinkType } from '../../../../../../types/LinkType';

type InputProps = {
  link: LinkType;
  label: string | undefined;
  validation: ValidationType;
  key: string;
};

export const InputBlock = ({ item: { link, label, validation, key } }: { item: InputProps }) => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [inputText, setInputText] = useState<string | undefined>(undefined);

  const inputRef = useRef<HTMLInputElement>(null);

  const href = useDocumentUrlWithNode(link.internalReference);

  const validateInput = useCallback(() => {
    if (!validation || validation === ValidationType.noValidation) setIsValid(true);
    switch (validation) {
      case ValidationType.postalCode:
        if (errorMessage !== ValidationMessage.postalCode) setErrorMessage(ValidationMessage.postalCode);
        if (inputText !== undefined) setIsValid(/^\d{4}$/.test(inputText));
        break;
      default:
        break;
    }
  }, [errorMessage, inputText, validation]);

  const handleSubmit = () => {
    if (isValid && inputText) {
      setShowErrorMessage(false);
      if (href) {
        const params =
          validation &&
          Object.values<string>(ValidationType).includes(validation) &&
          validation !== ValidationType.noValidation
            ? `?${ValidationType[validation]}=${inputText}`
            : `?value=${inputText}`;
        history.push({ pathname: href, search: params });
        return;
      }
      if (link.externalTarget?.href) {
        const url =
          validation &&
          Object.values<string>(ValidationType).includes(validation) &&
          validation !== ValidationType.noValidation
            ? `${link.externalTarget?.href}?${ValidationType[validation]}=${inputText}`
            : `${link.externalTarget.href}?value=${inputText}`;
        if (link.externalTarget?.blank) window.open(url);
        else window.location.href = url;
      }
    } else {
      setShowErrorMessage(true);
    }
  };

  useEffect(() => {
    // Clear error message, will only show on button submit
    if (showErrorMessage) setShowErrorMessage(false);
    if (inputText !== undefined) validateInput();
  }, [inputText, showErrorMessage, validateInput]);

  return (
    <InputBlockWrapper>
      {label && <StyledLabel htmlFor={`inputFieldImageBlock${key}`}>{label}</StyledLabel>}
      <InputAreaWrapper>
        <StyledInput
          ref={inputRef}
          id={`inputFieldImageBlock${key}`}
          value={inputText}
          onChange={(value: string) => setInputText(value)}
          error={inputText ? !isValid : false}
        />
        <Button type="button" onClick={() => handleSubmit()} size="large" variant="secondary">
          {link.title}
        </Button>
      </InputAreaWrapper>
      {showErrorMessage && <StyledMessage error>{errorMessage}</StyledMessage>}
    </InputBlockWrapper>
  );
};

const InputBlockWrapper = styled.div`
  max-width: 410px;
`;

const StyledLabel = styled(Label)`
  color: ${nafColor.signature.white || '#FFF'};
`;

const StyledInput = styled(Input)`
  width: auto;
  @media (max-width: ${breakpoints.s}) {
    width: 35%;
  }
`;

const InputAreaWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${spacing.space24};
  @media (max-width: ${breakpoints.s}) {
    justify-content: flex-start;
  }
`;

const StyledMessage = styled(Message)`
  background: ${({ theme }) =>
    theme.componentColors ? theme.componentColors.alert.errorBackground : theme.componentColors.alert.errorBackground};
  padding: ${spacing.space32} ${spacing.space24};
  margin-top: ${spacing.space24};
  color: ${nafColor.signature.black || '#000'};
  svg {
    color: ${({ theme }) => theme.componentColors.alert.error};
  }
`;
